<template>
  <div class="list">
    <div v-if="isSuccess && detail">
      <div class="head">
        <img src="../assets/success-icon.png" alt="" />
        <p>恭喜您投保成功!</p>
      </div>
      <div class="msg">
        <div class="name">{{ productName }}</div>
        <div class="line"></div>
        <div class="list">
          <h3>保障详情</h3>
          <div class="item">
            <p>预防接种异常反应意外身故保险金</p>
            <p>{{ detail.RBYWBXJ }}</p>
          </div>
          <div class="item">
            <p>预防接种异常反应意外伤残保险金</p>
            <p>{{ detail.RBSCBXJ }}</p>
          </div>
          <div class="item">
            <p>预防接种异常反应意外医疗费用保险金</p>
            <p>{{ detail.RBYWYLFBXJ }}</p>
          </div>
          <div class="item">
            <p>预防接种异常反应住院津贴保险金</p>
            <p>{{ detail.RBZYJTREBZ }}</p>
          </div>
          <div class="item">
            <p>生效日期</p>
            <p>{{ detail.activeDate }}</p>
          </div>
          <div class="item">
            <p>终止日期</p>
            <p>{{ detail.guaranteeDate }}</p>
          </div>
          <div class="item">
            <p>投保人</p>
            <p>{{ detail.applicantName }}</p>
          </div>
          <div class="item">
            <p>被保人</p>
            <p>{{ detail.insuredName }}</p>
          </div>
        </div>
      </div>
      <div class="tips">【请到“人保健康生活”微信公众号查询您的电子保单】</div>
    </div>
  </div>
</template>

<script>
import { wXH5PayOrderQuery, rbUnderwriting, rbInformation } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      timer: null,
      num: 1,
      isSuccess: null,
      detail: null,
      productName: sessionStorage.getItem("productName"),
    };
  },
  created() {
    let _this = this;
    _this.timer = setInterval(function () {
      _this.num++;
      if (_this.num > 4) {
        clearInterval(_this.timer);
        _this.timer = null;
      } else {
        _this.paySeeOrderWx();
      }
    }, 1000);
  },
  methods: {
    //获取出单信息
    rbInformation() {
      let params = {
        orderNo: sessionStorage.getItem("orderNo"),
      };
      rbInformation(params).then((res) => {
        if (res.success) {
          this.detail = res.result;
        }
      });
    },
    //承保
    rbUnderwriting() {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      var day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      var dateStr = year + "-" + month + "-" + day;
      let params = {
        payWay: "1",
        orderNo: sessionStorage.getItem("orderNo"),
        paymentConfirmaDate: dateStr,
        recordId: sessionStorage.getItem("recordId"),
      };
      rbUnderwriting(params).then((res) => {
        if (res.success) {
          this.rbInformation();
        } else {
          //跳转投保失败
          this.$router.push({
            path: "/insuranceFailure",
            query: {
              msg: res.message,
            },
          });
        }
      });
    },
    //是否支付成功
    wXH5PayOrderQuery() {
      let params = {
        orderNo: sessionStorage.getItem("orderNo"),
        channelId: "wx150dd25745296b4e",
      };
      wXH5PayOrderQuery(params).then(async (res) => {
        if (res.success) {
          clearInterval(this.timer);
          this.timer = null;
          await this.rbUnderwriting();
          await this.rbInformation();
          this.isSuccess = true;
        } else {
          if (this.num == 4) {
            this.$toast.clear();
            this.$router.push({
              path: "/payError",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  padding: 56px 0;
  text-align: center;
}
.msg {
  width: 350px;
  height: 438px;
  margin: 0 auto;
  background: url("../assets/success-bg.png") no-repeat;
  background-size: 100%;
  margin-top: -64px;
  .name {
    width: 220px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 37px;
  }
  .line {
    width: 89%;
    margin: 0 auto;
    height: 2px;
    border-bottom: 2.5px dashed #ededed;
    margin-top: 21px;
  }
  .list {
    padding: 0 30px;
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      margin-top: 19px;
      margin-bottom: 12px;
    }
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-bottom: 17px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
  }
}
.head {
  width: 100%;
  height: 183px;
  background: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 67px;
    margin-top: 16px;
  }
  p {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    margin-top: 16px;
  }
}
</style>